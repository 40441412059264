/* Playfair */
@font-face {
    font-family: "Playfair Display";
	font-style: normal;
	src: url("../fonts/PlayfairDisplay-VariableFont_wght.woff2") format("woff2");
}

/* Raleway */
@font-face {
    font-family: "Raleway";
	font-style: normal;
	src: url("../fonts/Raleway-VariableFont_wght.woff2") format("woff2");
}

/* Linea Arrows */
@font-face {
    font-family: "Linea Arrows";
	font-style: normal;
	src: url("../fonts/linea-arrows-10.woff") format("woff");
}

/* Icomoon custom icons */
@font-face {
    font-family: "icomoon";
    src:  url("../fonts/icomoon.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@mixin heading-medium {
    font-size: 1.625rem;
    line-height: 2.0625rem;
}