.story {
    &.component {
        background-color: $color-white;
    }

    .component-content {
        display: flex;
        flex-wrap: wrap;
        padding-top: 0;
        text-align: center;

        .subheading {
            color: $color-gray-dark;
            font-weight: 900;
            margin: 0 auto;
            @include main-heading;
        }

        .text {
            margin: 0 auto;
            max-width: 30rem;
        }
    }
}