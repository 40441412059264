footer {
    $content-margin: responsive-property(xxsmall, content-margin);
    $bottom-padding: 3rem;
    $form-field-gap: 0.2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, min-content);
    
    background-color: $color-off-white;
    font-size: 0.875rem;
    padding: $content-margin $content-margin 0;
    text-align: center;

    .footer__text {
        h2 {
            font-family: "Playfair Display";
            font-weight: 700;
            @include heading-medium;
        }

        p {
            line-height: 1.375rem;
        }
    }

    .contact-form {

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(6, min-content);
        justify-items: center;
        padding-bottom: $component-gap;

        input,
        #message {
            font-family: "Raleway";
            font-size: 0.875rem;
            width: 100%;
            box-sizing: border-box;
            margin: $form-field-gap 0;
            z-index: 1;
        }

        input[type="text"],
        input[type="email"],
        #message {
            border: 1px solid $color-faint;
            padding: 0.4rem;
        }

        #message {
            resize: vertical;
            min-height: 7rem;
        }
            
        input[type="submit"] {
            flex: 0;
            display: block;
            margin-top: 1rem;
            width: auto;
        }
    
        .consent-set {
            margin-top: 0.25rem;

        }

        input[type="checkbox"] {
            width: auto;

            .safari & {
                margin-top: 1.25rem;
                transform: scale(1.5, 1.5);
            }
        }

        .consent-label {
            display: inline;
            color: $color-gray-1;
            font-size: 0.75rem;
        }
    }

    address,
    .contact,
    .copyright,
    .footer__links {
        font-size: 0.75rem;
        background-color: #fff;
        line-height: 1.375rem;
        // bg is full width, content on grid
        margin: 0 calc( $content-margin * -1 );
        padding: 0 $content-margin;
    }

    .copyright {
        order: 2;
        padding-bottom: $bottom-padding;
    }

    .footer__links {
        justify-content: center;
        display: flex;
        order: 1;
        padding: $component-gap 0 $component-gap-small;

        li {
            margin: 0 1rem;

            a {
                text-decoration: none;
            }
        }
    }

    address,
    .contact {
        padding-top: $component-gap;
    }

    // Custom breakpoint
    @media (min-width: 450px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, min-content);
        grid-template-areas:
            "text text"
            "form form"
            "address links"
            "address contact"
            "copyright copyright";

        .footer__text {
            grid-area: text;
        }

        .contact-form {
            grid-area: form;
            grid-template-columns: 1fr calc( 50% + 25px );
            grid-template-rows: repeat(3, min-content);
            grid-template-areas:
                "details details"
                "message message"
                "submit consent";
            justify-items: start;

            .details {
                grid-area: details;
            }

            #message {
                grid-area: message;
                margin-bottom: 0.55rem;
            }            

            flex-wrap: nowrap;
            text-align: left;

            .consent-set {
                display: flex;
                grid-area: consent;
                margin-left: 0.5rem;
                margin-top: -1.13rem;
            }

            .consent-label {
                margin-left: 0.3rem;
                margin-top: 1.2rem;
                padding-right: 0.5rem;
            }

            // Disable no-wrap
            .no-wrap {
                display: inline;
            }
        
            input[type="submit"] {
                grid-area: submit;
                margin-top: 0;
                max-height: 34px;
            }
        }
        
        address,
        .contact,
        .copyright,
        .footer__links {
            text-align: left;
        }

        address {
            grid-area: address;
            text-align: left;
        }

        .contact {
            grid-area: contact;
            margin-left: 0;
            padding: $component-gap-small 0 0 0;
        }

        .copyright {
            grid-area: copyright;
            order: 0;
            text-align: left;
            padding-top: $component-gap-small;
        }

        .footer__links {
            grid-area: links;
            justify-content: start;
            margin-left: 0;
            padding: $component-gap 0 0 0;

            li:first-child {
                margin: 0;
            }
        }
    }

    @include media-width(min-width, small) {
        .contact-form {
            column-gap: $component-gap-small;
            grid-template-columns: 1fr 50%;
            grid-template-areas:
                "details message"
                "details message"
                "consent submit";
            justify-items: start;
            
            .consent-set {
                margin-left: 0;
            }

            .details {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            #message {
                min-height: 9.0069rem;

                .safari & {
                    min-height: 8.894rem;
                }
    
                .firefox & {
                    min-height: 9.074rem;
                }
            }
        }
    }

    @include media-width(min-width, smallish) {
        $content-margin: responsive-property(smallish, content-margin);

        grid-template-columns: $content-margin 1fr 1fr $content-margin;
        grid-template-areas:
            ". text text ."
            ". form form ."
            "address address links links"
            "address address contact contact"
            "copyright copyright copyright copyright";
        padding: 1.25rem 0 0;

        address,
        .copyright {
            margin: 0;
            padding-left: $content-margin;
        }
        
        .contact,
        .footer__links {
            margin-right: 0;
        }
    }

    // Custom breakpoint
    @media (min-width: 800px) {
        grid-template-columns: $content-margin 1fr 1fr 1fr $content-margin;
        grid-template-areas:
            ". text text text ."
            ". form form form ."
            "address address contact links links"
            "address address copyright copyright copyright";

        .contact-form {
            grid-template-columns: calc( 33.33% - $component-gap-small ) min-content 1fr;
            grid-template-areas:
                "details message message"
                "details message message"
                ". submit consent";

            .no-wrap {
                display: inline-block;
            }
        }

        .contact {
            padding-top: $component-gap;
        }

        .copyright {
            padding-left: 0;
        }

        .footer__links {
            padding: $component-gap $content-margin 0 0;
            justify-content: flex-end;

            li:last-child {
                margin-right: 0;
            }
        }
    }


    @include media-width(min-width, extra-large) {
        $content-margin: responsive-property(extra-large, content-margin);
        $leading: 1.375rem;
        padding-bottom: 3rem;

        grid-template-columns: $content-margin 12rem 1fr 6rem $content-margin;

        // Num lines * line-height (1.375rem) + 1rem gap
        // Bottom row expands with textarea
        grid-template-rows: max-content 5.125rem 2.375rem max-content;
        
        grid-template-areas:
            ". text text text ."
            ". address form links ."
            ". contact form . ."
            ". copyright form . .";

        address,
        .contact,
        .copyright,
        .footer__links {
            background-color: transparent;
            padding: 0;
        }  

        .footer__links {
            flex-wrap: wrap;

            li, li:first-child {
                flex: 0 1 100%;
                margin: 0 0 0 2.7rem;
            }
        }

    }

    @include media-width(min-width, super-large) {
        $content-margin: responsive-property(super-large, content-margin);
        $side-col: calc( $content-margin - 2rem);

        grid-template-columns: $side-col 14rem 3fr 8rem $side-col;
        column-gap: 2rem;

        .footer__links {
            li, li:first-child {
                flex: 0 1 100%;
                margin: 0 0 0 4.7rem;
            }
        }
    }

    @include media-width(min-width, uber-large) {
        .home & {
            // Compensate for sticky title
            margin-top: 150px;
        }
    }
}