.image {
    
    .component-content {
        overflow-x: hidden;
        padding: 0;    
    }

    img {
        margin: 0 auto;
        width: 100vw;
    }

    @include media-width(min-width, medium) {
        &.component--page-width img {
            width: 100vw;
        }
        
        &.component--content-width img,
        &.component--text-width img {
            padding: $component-gap 0;
            width: 80vw;
        }
    }
}