@import
"./variables",
"./helpers",
"./reset.scss",
"./fonts.scss",
"../components/nav/nav",
"../components/footer/footer",
"../components/sticky-title/sticky-title",
"../components/gallery/gallery",
"../components/text-section/text-section",
"../components/story/story",
"../components/image/image",
"../components/video/video",
"../components/testimonial/testimonial",
"../components/carousel/carousel",
"./project";

.playfair {
    font-family: 'Playfair Display';  
    font-weight: 900;  
}

.raleway {
    font-family: 'Raleway';
    font-weight: 800;
}

html,body {
    height:100%;
}

body {
    font-family: "Raleway";
    font-weight: 400;

    &.nav-active {
        overflow: hidden;
    }

    &.home {
        background-color: $color-cp-orange;
    }
}

h1 {
    font-size: 2.125rem;
    font-family: 'Playfair Display';  
    font-weight: 700;
    line-height: 2.59rem;
}

.subheading {
    font-family: "Playfair Display";
    font-size: 1.1875rem;
    font-weight: 700;
    padding-bottom: 1rem;
}

p { 
    $v-spacing: 1.625rem;
    font-size: 0.875rem;
    line-height: 1.625rem;
    margin: 0.75rem 0;
}

img,
video {
    display: block;
}

form .form-label.form-label--hidden {
    @extend %screen-reader-text;
}

button .bttn__text.bttn__text--hidden {
    font-size: 0;
}

.bttn-cp {
    background: transparent;
    border: solid white 1px;
    color: #fff;
    font-family: Raleway;
    font-weight: 700;
    padding: 0.7rem 1.4rem;
    text-transform: uppercase;

    .bttn__text {
        padding: 0.7rem 1.4rem;
        text-transform: uppercase;
    }
}

.bttn-cp--form {
    background-color: $color-white;
    border-color: $color-cp-orange;
    color: $color-cp-orange;
    padding: 0.45rem 1.4rem;

    .bttn__text {
        padding: 0.45rem 1.4rem;
    }
}

.bttn-icon {
    background: transparent;
    border: none;
    position: absolute;

    &:before {
        top: 4px;
        left: 1px;
    }
}

.content-sticky-wrapper {    
    $block-size: 16.66vw;
    
    @include media-width(min-width, uber-large) {
        margin-top: calc( ( $block-size * -2) - 77px);

        .content {
            position: sticky;
            margin-top: calc( $block-size + 154px);
            top: calc( ( $block-size * 2) + 77px);

            .firefox & {                
                margin-top: calc( $block-size + 77px);
            }
        }
    }
}

main {
    position: relative;
    z-index: 4;

    .home & {
        // Send behind nav (z-index 3) so title can scroll behind it
        margin-top: 0;
        z-index: 2;
    }
}

.component {
    background-color: $color-cp-orange;
}

.component--rev {
    color: $color-white;
}

.component--h {
    /* 
        These styles will be used to adjust the positon of adjacent elements at wider screen sizes 
        The basic text alignments will come from the component styles
    */
    &-center {
         /* Elements are centered */
    }

    &-left {
        /* Text on left */
    }

    &-right {
        /* Text on right */
    }
}

.component--v {
    &-top {
        /* text above image */
    }

    &-bottom {
        /* text below image */
    }
}

.component-content {
    box-sizing: border-box;
    column-gap: 2rem;
    font-size: 0.875rem;
    line-height: 1.625rem;
    padding: $component-gap 0;

    &.untitled {
        padding: 1.2665rem 0;
    }

    @include component-width(xxsmall);

    @include media-width(min-width, small) {
        @include component-width(smallish);
        
        &,
        &.untitled {
            padding: $component-gap-large 0;
        }
    }

    @include media-width(min-width, medium) {
        .image + .text-section &.untitled {
            padding: 0;
        }
    }

    @include media-width(min-width, large) {
         @include component-width(large);
    }

    .image &,
    .video & {
        // Image and video components are full width for narrow screens
        @include media-width(max-width, medium) {
            margin: 0;
            width: 100vw;
        }
    }

    img + img:not(:last-child), 
    picture + picture:not(:last-child){
        padding: 0.4rem 0;
    }
}

@include media-width(min-width, xsmall) {
    h1 {
        font-size: 2.8125rem;
        line-height: 3.4375rem;
    }
}

