$color-cp-orange: #f47832;
$color-gray: #8e8e8e;
$color-gray-1: #6a6a6a;
$color-gray-dark: #303030;
$color-off-white: #f2f2f2;
$color-faint: #E3E3E3;
$color-white: #fff;

$logo-size: 3.1875rem;
$nav-height: 4.8125rem;
$component-gap: 2.5rem;
$component-gap-large: 3.75rem;
$component-gap-small: 1.25rem;
$column-gap: 2rem;