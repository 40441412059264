.video {

    .component-content {
        overflow-x: hidden;
        padding: 0;    
    }

    video {
        width: 100%;
    }
}