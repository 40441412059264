@mixin responsive-block($block_size) {
    flex: 0 1 $block_size;
    height: $block_size;

    .gallery__info {
        $info_size: $block_size;
        height: $info_size;
        width: $info_size;
    }
}

%gallery-icon {
    @extend %bttn-icon;
    color: #fff;
    font-size: 1.5rem;
    position: absolute;
    width: 30px;
}

.gallery {
    width: 100vw;

    &__blocks {
        $width: 100vw;
        display: flex;
        flex-wrap: wrap;     
        
        // Placeholder colors
        #block--0 {
            background: rgb(120, 195, 86);
        }
        #block--1 {
            background: rgb(86, 195, 188);
        }
        #block--2 {
            background: rgb(144, 86, 195);
        }
        #block--3 {
            background: rgb(90, 86, 195);
        }
        #block--4 {
            background: rgb(195, 171, 86);
        }
        #block--5 {
            background: rgb(195, 86, 148);
        }
        #block--6 {
            background: rgb(177, 195, 86);
        }
        #block--7 {
            background: rgb(241, 131, 216);
        }
        #block--8 {
            background: rgb(86, 195, 135);
        }
        #block--9 {
            background: rgb(35, 115, 176);
        }
        #block--10 {
            background: rgb(195, 195, 86);
        }
        #block--11 {
            background: rgb(120, 195, 86);
        }
    }

    &__block {
        background-color: rgb(171, 171, 171);
        flex: 0 1 50vw;
        height: 50vw;
        position: relative;
        width: 50vw;
        
        &--active {
            z-index: 1;
        }

        &:nth-child(odd) {
            background-color: rgb(219, 194, 86);
        }

        &:nth-child(3n) {
            background-color: rgb(73, 141, 118);
        }

        .bttn-icon--info-on::before {
            @extend %gallery-icon;
            content: "\e901";
            transition: opacity 0.5s ease;
        }

        .bttn-icon--info-off::before {
            @extend %gallery-icon;
            content: "\e900";
        }

        .gallery__info {
            background-color: $color-cp-orange;
            box-sizing: border-box;
            height: 50vw;
            opacity: 0;
            pointer-events: none;
            position: relative;
            width: 100vw;
            transition: opacity 0.5s ease;

            &-content {
                display: flex;
                flex-direction: column;
                height: 30%;
                justify-content: space-around;
            }

            &-description {
                color: #fff;
                flex: 1 1 100%;
                font-family: 'Playfair Display';
                font-size: 0.875rem;
                font-weight: 500;
                line-height: 1.625rem;
                padding: 30% calc(50% - 115px) 16px;
                text-align: center;
            }

            & .bttn-cp--case {
                margin: -0.6rem auto 0 auto;
            }
        }

        &--active .gallery__info {
            opacity: 1;
            pointer-events: auto;
        }
        
        &.occluded {
            .bttn-icon--info-on::before,
            .gallery__block--active .gallery__info {
                opacity: 0;
                pointer-events: none;
            }
        }

        &:nth-child(even) .gallery__info {
            left: -50vw;
        }
        
        @include media-width(min-width, xsmall) {
            @include responsive-block(50vw);
            
            &:nth-child(even) .gallery__info {
                left: 0;
            }

            .gallery__info-content {
                height: auto;
            }

        }

        @include media-width(min-width, medium) {
            @include responsive-block(33.33vw);
        }

        @include media-width(min-width, super-large) {
            @include responsive-block(25vw);
        }

        @include media-width(min-width, uber-large) {
            @include responsive-block(calc( 100vw / 6 ));
        }

        @media (hover: hover) {
            // No need for buttons on devices that support hover
            .bttn-icon {
                @include screen-reader-text;
            }

            &:hover .gallery__info {
                opacity: 1;
                pointer-events: auto;
                z-index: 1;
            }
        }
    }

    // uber-large has title panel scrolling rather than gallery images
    @include media-width(min-width, uber-large) {
        height: 50vw;
        position: relative;
        top: 77px;

        .gallery__blocks {
            position: sticky;
            top: 77px;
        }
    }

}