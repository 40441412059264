.project {    
    &-header {
        &.component {
            background-color: $color-white;
            margin-top: 1rem;
        }
        
        .overview {
            margin-top: -2rem;
        }

        .display #headline {
            @include main-heading;
        }
        
        .component-content {
            column-gap: 0;
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 0;

            .head--client {
                font-size: 1.1875rem;
                font-weight: 900;
                margin-bottom: -0.5rem;
            }

            .overview {
                order: 1;
            }

            .display {
                order: 2;
            }

            .hero {
                $margin-adjustment: calc( responsive-property(xxsmall, text-margin) * -1 );
                margin: 0 $margin-adjustment -0.6rem;
                order: 3;
                overflow: hidden;

                &-image {
                    margin-left: -50vw;
                    width: 200vw;
                }
            }
        }        
    }
    
    .carousel {
        margin: $component-gap-small 0;
    }

    // @include media-width(min-width, small) {
    @media (min-width: page-width(small)) {
        .component-content {
            .overview {
                flex: calc( 50% - 2rem );
                margin: -1.25rem 0 0;
                padding-right: $column-gap;

                .scope {
                    max-width: 30ch;
                }
            }
            .display {
                flex: 50%;
                margin-top: -2rem;
            }

            .hero {
                margin: 0 -10vw;
            }
        }
    }

    @include media-width(min-width, smallish) {
        $content-width: responsive-property(smallish, content-width);
        $content-margin: responsive-property(smallish, content-margin);

        .component {
            position: relative;

            &.project-header { 
                /* background-color applied in /dist/css/custom/<project-name>.css */
                margin-top: 0;
                
                .component-content {
                    box-sizing: border-box;
                    padding: $component-gap-small 0 0;
                    background-color: $color-white;
                    border-top: 1rem solid $color-white;
                    padding: $component-gap-small 10vw 0;
                    width: 100vw;
                    
                    .overview {
                        flex: auto;
                        margin: 0;
                        width: calc(50% - 2rem);

                        .scope {
                            max-width: none;
                        }
                    }

                    .display {
                        flex: 50%;
                        margin: -0.75rem 0 0;
                    }

                    .hero {
                        margin: 0 -10vw;
                        width: 100vw;

                        &-image {
                            margin-left: 0;
                            width: 100%;

                        }
                    }    
                }
            }            
        }
    }        

    @include media-width(min-width, medium) {
        &-header {
            margin-top: 3rem;

            .component-content {
                .overview {
                    .head--client {
                        font-size: 1.375rem;
                        line-height: 1.9375rem;
                    }
                }
                
                .display {
                    margin-top: -2rem;
                }
            }
        }
    }

    @include media-width(min-width, large) {
        $text-width: responsive-property(large, text-width);
        $content-width: responsive-property(large, content-width);

        .component {
            &.project-header { 
                /* background-color applied in /dist/css/custom/<project-name>.css */
                margin-top: 3rem;
                
                .component-content {
                    padding: $component-gap-small 0 0;
                    width: $text-width;

                    .overview .head--client {
                        font-size: 1.5625rem;
                        line-height: 2.1875rem;
                    }

                    .hero {
                        width: $content-width;
                    }
                }
            }
        }
    }
}