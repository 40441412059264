.text-section {    
    .component-content {
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        .subheading,
        .text {
            width: 100%;
        }

        &.untitled .text {
            text-align: center;
        }
    }

    @include media-width(min-width, small) {
        $gutter: 2rem;
        gap: $gutter;
        
        .component-content {
            flex-wrap: nowrap;
            text-align: left;


            .subheading {
                flex: auto;
                width: calc( 50% - $gutter );
            }

            .text {
                flex: 50%;
                margin-top: -0.6rem;
            }
        }
    }

    @include media-width(min-width, medium) {
        .component-content .subheading {
            font-size: 1.375rem;
            line-height: 1.875rem;
        }
    }

    @include media-width(min-width, large) {
        .component-content .subheading {
            font-size: 1.5625rem;
            line-height: 2.125rem;
        }
    }
}