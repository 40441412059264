$sticky-title-y: 77px;

.sticky-title {
        &.component {
            background-color: transparent;
        }
        
        height: 400vw;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: 2;


    &__block {
        $padding: responsive-property(xxsmall, content-margin);

        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vw;
        padding: $padding;
        position: sticky;
        top: calc( 50vw + $sticky-title-y );
        width: 100vw;

        &-content {
            margin-top: -0.5rem;
        }
    }
}

// Custom breakpoint
@media (min-width: 340px){
    .sticky-title__block {
        padding: 12%;
    }
}

// Custom breakpoint
@media (min-width: 415px){
    .sticky-title__block {
        padding: 20%;
    }
}

@include media-width(min-width, xsmall) {
    .sticky-title {
        height: 350vw;

        &__block {
            height: 50vw;
            padding: responsive-property(smallish, content-margin);

            &-content {
                margin-top: 0;
            }
        }
    }
}

@include media-width(min-width, smallish) {
    .sticky-title__block {
        padding: 20vw;
    }
}

@include media-width(min-width, medium) {
    .sticky-title {
        $block-size: 33.33vw;
        height: 100vw;
        
        &__block {
            height: $block-size;
            margin-left: $block-size;
            padding: 2vw;
            top: calc( $block-size + $sticky-title-y );
            width: 66.66vw;
        }
    }
}

// Custom breakpoint
@media (min-width: 800px){
    .sticky-title__block {
        padding: 10vw;
    }
}

@include media-width(min-width, large) {
    .sticky-title__block {
        padding: 15vw;
    }
}

@include media-width(min-width, super-large) {
    .sticky-title {
        $block-size: 25vw;
        height: 75vw;
    
        &__block {
            height: $block-size;
            margin-left: $block-size;
            padding: 10vw;
            top: calc( $block-size + $sticky-title-y );
            width: calc( $block-size * 2 );
        }
    }
}

// custom breakpoint
@media (min-width: 1500px) {
    .sticky-title__block {
        padding: 12vw;
    }
}

@include media-width(min-width, uber-large) {
     
    .sticky-title {
        $block-size: calc( 100vw / 6 );
        height: 33.33vw;

        &__block {
            height: $block-size;
            margin-left: 33.33vw;
            padding: 5vw;
            width: 33.33vw;
            position: relative;
            top: 77px;
        }
    }     
}