.nav-sticky-wrapper {
    position: relative;
    z-index: 100;
}

.nav {
    $bar-height: 3px;
    $bar-transition: 0.5s;
    $bar-width: 26px;
    $nav-height: 77px;
    $content-margin: responsive-property(xxsmall, content-margin);
    $toggle-padding: 30px;
    $toggle-size: 17px;

    background-color: $color-cp-orange;
    color: #fff;
    font-family: "Raleway";
    font-weight: 700;
    font-size: 0.8125rem;
    line-height: 3.2rem;
    height: $nav-height;
    position: relative;
    text-transform: uppercase;

    &:before {
        background-color: #000;
        content: "";
        height: 100%;
        height: 100vh;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 100vw;
        z-index: -1;
        transition: opacity $bar-transition;
    }

    .nav-active &:before {
        opacity: 0.6;
        pointer-events: all;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    &__elements {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 $content-margin;

        &__nav__element {
            display: block;
        }
    }

    &__home {
        order: 1;

        &-link {
            background-image: url("../img/creativepulse_logo.svg");
            background-size: cover;
            border: 1px solid #fff;
            box-sizing: border-box;
            display: block;
            height: $logo-size;
            width: $logo-size;
            margin-top: calc(($nav-height - $logo-size) / 2);
        }
    }

    &__submenu {
        order: 0;

        &-toggle {
            height: $toggle-size;
            padding: 30px 10px 14px 0;
            top: 0;
            width: $toggle-size;

            &-bar {
                background-color: rgba(255, 255, 255, 1);
                border-radius: 0;
                border: none;
                height: $bar-height;
                padding: 0;
                position: absolute;
                transition: $bar-transition;
                width: $bar-width;

                &:before {
                    top: -8px;

                    .nav-active & {
                        transform: rotate(45deg);
                    }
                }

                &:after {
                    top: 8px;

                    .nav-active & {
                        transform: rotate(135deg);
                    }
                }

                &:before,
                &:after {
                    content: "";
                    background-color: $color-white;
                    height: $bar-height;
                    left: 0;
                    position: absolute;
                    transition: $bar-transition;
                    width: $bar-width;

                    .nav-active & {
                        top: 0;
                    }
                }

                .nav-active & {
                    background-color: rgba(255, 255, 255, 0);
                }

            }

        }

        &-entries {
            $submenu-entries-width: calc(100vw - ($logo-size + $content-margin ));
            background-color: $color-cp-orange;
            height: 100vh;
            left: calc($submenu-entries-width * -1);
            position: absolute;
            right: 0;
            top: 4.3rem;
            width: $submenu-entries-width;
            transition: all 0.5s ease;

            .resizing & {
                transition: none;
            }

            .nav-active & {
                left: 0;
            }

            &-list {
                margin: 0 0 0 $content-margin;
            }
        }
    }

    &__tel-num {
        font-weight: 500;
    }

    @include media-width(min-width, xsmall) {
        $content-margin: responsive-property(xsmall, content-margin);

        &__submenu-entries {
            width: calc(100vw - ($logo-size + $content-margin ));
        }
    }

    @include media-width(min-width, smallish) {

        $content-margin: responsive-property(smallish, content-margin);

        .nav-active &:before {
            opacity: 0;
            pointer-events: all;
        }

        &__elements {
            margin: 0 $content-margin;
            position: relative;
            width: responsive-property(smallish, content-width);
        }

        &__submenu {

            &-toggle {
                display: none;
            }

            &-entries {
                height: $nav-height;
                left: 0;
                top: 0;
                width: calc(100vw - ($logo-size + ($content-margin * 2)));
                transition: none;

                &-list {
                    display: flex;
                    margin: 0;
                    padding-top: 13px;

                    &-link:first-child {
                        padding-right: 55px;
                    }

                    &-tel {
                        flex: 0 1 calc(50% - ($logo-size / 2));
                        margin-left: auto;
                    }
                }
            }
        }
    }

    @include media-width(min-width, super-large) {

        $content-margin: responsive-property(super-large, content-margin);

        &__elements {
            margin: 0 $content-margin;
            width: responsive-property(super-large, content-width);
        }

        &__submenu-entries {
            width: calc(100vw - ($logo-size + ($content-margin * 2)));
        }
    }
}

// uber-large has title panel scrolling rather than gallery images    
@include media-width(min-width, uber-large) {

    .home .nav-sticky-wrapper {
        height: calc((50vw / 3) + 77px);
        position: absolute;
        top: 0px;
    }

    .nav {
        position: sticky;
        top: 0;
    }
}